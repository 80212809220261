import React from "react";
import Image from "next/image";

export default function Icon({
  icon,
  width = 40,
  height = 40,
}: {
  icon: string;
  width?: number;
  height?: number;
}) {
  return (
    <Image
      src={`/images/icon_${icon}.svg`}
      alt={`${icon} icon`}
      width={width}
      height={height}
    />
  );
}
