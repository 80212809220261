import React, { useContext, useEffect, useState } from "react";
import modalStyles from "@/styles/Modal.module.scss";
import utilStyles from "@/styles/utils.module.scss";
import UserItem from "../UserItem";
import Image from "next/image";
import { BaseStateContext } from "@/contexts/BaseContext";
import { motion } from "framer-motion";
import BaseModal from "./BaseModal";
import Api from "@/api";
import { ListDescriptionLikesView } from "@/api/generated";
import { useBaseActions } from "@/hooks/useBaseActions";

export default function LikeModal({
  modalType,
  descriptionId,
}: {
  modalType: string;
  descriptionId: string;
}) {
  const [likedUsers, setLikedUsers] = useState<ListDescriptionLikesView>();
  const baseState = useContext(BaseStateContext);
  const { closeModal } = useBaseActions();

  useEffect(() => {
    if (!descriptionId) return;
    Api.Descriptions.getApiDescriptionLikesList(descriptionId).then((res) => {
      console.log(res.data);
      setLikedUsers(res.data);
    });
  }, []);

  function clickClose(e: any) {
    e.stopPropagation();

    closeModal();
  }

  const modalVariant = {
    hidden: {
      y: "100vh",
    },
    visible: {
      y: 0,
      transition: {
        type: "tween",
        duration: 0.2,
      },
    },
  };

  if (baseState.modalType !== modalType) return null;
  if (!likedUsers?.likes) return null;

  return (
    <BaseModal modalType={modalType}>
      <motion.div
        className={[modalStyles.modalInner, modalStyles.modalLike].join(" ")}
        variants={modalVariant}
      >
        <div className={modalStyles.section}>
          <h2 className={modalStyles.sectionTitle}>Likes</h2>
          <button onClick={clickClose}>
            <Image
              src="/images/icon_cross_black.svg"
              alt="Close icon"
              width={24}
              height={24}
            />
          </button>
        </div>

        <div className={[utilStyles.users, modalStyles.likeList].join(" ")}>
          {likedUsers.likes.map((like) => {
            if (like?.author) {
              return (
                <UserItem user={like?.author} key={like?.author.id || 0} />
              );
            }
          })}
        </div>
      </motion.div>
    </BaseModal>
  );
}
