import React from "react";
import moment from "moment";

export default function DateTime({ timestamp }: { timestamp?: number }) {
  if (!timestamp) return null;

  const datetime = moment.unix(timestamp);

  return <>{datetime.fromNow()}</>;
}
