export const getLogoImageSrc = (coverImage = ""): string => {
  if (coverImage.startsWith("#")) {
    if (isBrightColor(coverImage)) {
      return "/images/logo-image01.png";
    }

    return "/images/logo-image02.png";
  }

  const imageMap: { [key: string]: string } = {
    coverImage01: "/images/logo-image01.png",
    coverImage02: "/images/logo-image02.png",
    coverImage03: "/images/logo-image03.png",
    coverImage04: "/images/logo-image04.png",
    coverImage05: "/images/logo-image05.png",
  };

  return imageMap[coverImage] ?? "/images/logo-image01.png";
};

export const getLogoImageIconSrc = (coverImage = ""): string => {
  const imageMap: { [key: string]: string } = {
    coverImage01: "/images/cover/coverImage01.png",
    coverImage02: "/images/cover/coverImage02.png",
    coverImage03: "/images/cover/coverImage03.png",
    coverImage04: "/images/cover/coverImage04.png",
    coverImage05: "/images/cover/coverImage05.png",
  };

  return imageMap[coverImage] ?? "/images/cover/coverImage01.png";
};

export const getLogoImageSrcMono = (coverImage = ""): string => {
  if (coverImage.startsWith("#") && isBrightColor(coverImage)) {
    return "/images/logo_black.svg";
  }

  return "/images/logo.svg";
};

export const isBlackFont = (coverImage = ""): boolean => {
  return coverImage.startsWith("#") && isBrightColor(coverImage);
};

export const isBrightColor = (colour: string): boolean => {
  const r = parseInt(colour.slice(1, 3), 16);
  const g = parseInt(colour.slice(3, 5), 16);
  const b = parseInt(colour.slice(5, 7), 16);

  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 155;
};
