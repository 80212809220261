import React, { useContext, useEffect } from "react";
import modalStyles from "@/styles/Modal.module.scss";
import { BaseStateContext } from "@/contexts/BaseContext";
import { AnimatePresence, motion } from "framer-motion";

export default function BaseModal({
  modalType,
  children,
}: {
  modalType: string;
  children: React.ReactNode;
}) {
  const state = useContext(BaseStateContext);

  const clickHandler = (e: any) => {
    e.stopPropagation();
  };

  const backdropVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        delayChildren: 0,
      },
    },
  };

  useEffect(() => {
    if (state.showModal) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    }
    return () => {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "unset";
      }
    };
  }, [state.showModal]);

  return (
    <>
      <AnimatePresence>
        {state.showModal && state.modalType === modalType && (
          <motion.div
            onClick={clickHandler}
            className={modalStyles.modalOuter}
            variants={backdropVariant}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
