import React, { useContext } from "react";
import postItemStyles from "@/styles/PostItem.module.scss";
import UserItem from "./UserItem";
import {
  FullDescriptionView,
  OwnerDescriptionView,
  ShortDescriptionView,
  UserMeView,
} from "@/api/generated";
import DateTime from "@/components/DateTime";
import LikeButton from "@/components/LikeButton";
import {
  DescriptionDispatchContext,
  DescriptionStateContext,
} from "@/contexts/DescriptionContext";
import Api from "@/api";
import { useRouter } from "next/router";
import { useAuthHeaders } from "@/lib/authHeader";

export default function Description({
  description,
  user,
  children,
  showLike = true,
}: {
  description:
    | OwnerDescriptionView
    | FullDescriptionView
    | ShortDescriptionView;
  user?: UserMeView;
  children?: React.ReactNode;
  showLike?: boolean;
}) {
  const { authHeader } = useAuthHeaders();
  const router = useRouter();
  const state = useContext(DescriptionStateContext);
  const dispatch = useContext(DescriptionDispatchContext);

  if (!description) {
    return null;
  }
  const author = description.author;
  const declined = description.status === "Declined";

  const myDescription = description.owner?.username === user?.username;

  const clickHandler = (e: any) => {
    e?.stopPropagation();
    if (!user) {
      router.push("/signup");
      return;
    }

    if (description.approved) {
      openDetails();
      return;
    }
    openPreview();
  };

  const openPreview = () => {
    dispatch({
      type: "OPEN_PREVIEW",
      payload: {
        descriptionId: description?.id || "",
      },
    });
  };

  const openDetails = () => {
    if (!authHeader) return;
    dispatch({
      type: "OPEN_PREVIEW",
      payload: {
        descriptionId: description?.id || "",
      },
    });

    dispatch({
      type: "UPDATE_OWNER_DESCRIPTIONS",
      payload: {
        descriptionIds:
          state.descriptions
            .filter((d) => d.owner?.id === description.owner?.id)
            .map((d) => d?.id || "") || [],
      },
    });

    Api.MyDescriptions.getApiDescriptionsMeRepliedDescription(
      description.id || "",
      authHeader
    ).then((res) => {
      dispatch({
        type: "ADD_DESCRIPTIONS",
        payload: {
          descriptions: [res.data],
        },
      });
      dispatch({
        type: "UPDATE_AUTHOR_DESCRIPTION",
        payload: {
          descriptionId: res.data.id || "",
        },
      });
    });

    Api.MyDescriptions.getApiDescriptionsMeListByUsername(
      author?.username || "",
      0,
      50,
      "updatedAt|desc",
      authHeader
    ).then((res) => {
      dispatch({
        type: "ADD_DESCRIPTIONS",
        payload: {
          descriptions: res.data.data || [],
        },
      });
      dispatch({
        type: "UPDATE_AUTHOR_DESCRIPTIONS",
        payload: {
          descriptionIds: (res.data.data || []).map(
            (description) => description?.id || ""
          ),
        },
      });
    });
  };

  return (
    <div className={postItemStyles.postItem} onClick={clickHandler}>
      {author && (
        <div className={postItemStyles.header}>
          <UserItem user={author} relation={description.relationship} />
        </div>
      )}

      {myDescription && declined ? (
        <div className={postItemStyles.declinedText}>
          <p>
            Notification sent to the dscribed. <br />
            Let them know what the problem was.
          </p>
          <span>If you sent the invitation by mistake, delete it now.</span>
        </div>
      ) : (
        <span className={postItemStyles.postText}>{description.text}</span>
      )}

      <div className={postItemStyles.footer}>
        <div className={postItemStyles.firstRow}>
          {showLike && <LikeButton description={description} />}
          {!declined && (
            <span className={postItemStyles.date}>
              <DateTime timestamp={description.updated_at} />
            </span>
          )}
        </div>
        {children && <div className={postItemStyles.secondRow}>{children}</div>}
      </div>
    </div>
  );
}
