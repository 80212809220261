import React, { useContext } from "react";
import postItemStyles from "@/styles/PostItem.module.scss";
import {
  FullDescriptionView,
  OwnerDescriptionView,
  ShortDescriptionView,
} from "@/api/generated";
import Api from "@/api";
import { DescriptionDispatchContext } from "@/contexts/DescriptionContext";
import { BaseStateContext } from "@/contexts/BaseContext";
import LikeModal from "@/components/Modal/LikeModal";
import { useAuthHeaders } from "@/lib/authHeader";
import { useBaseActions } from "@/hooks/useBaseActions";
import utilStyles from "@/styles/utils.module.scss";

export default function LikeButton({
  description,
  isDetail = false,
  isBlack = false,
}: {
  description:
    | OwnerDescriptionView
    | FullDescriptionView
    | ShortDescriptionView;
  isDetail?: boolean;
  isBlack?: boolean;
}) {
  const { authHeader } = useAuthHeaders();
  const baseState = useContext(BaseStateContext);
  const { showModal } = useBaseActions();
  const descriptionDispatch = useContext(DescriptionDispatchContext);
  const textStyle = isBlack ? utilStyles.blackText : utilStyles.whiteText;

  const clickLikeHandler = (e: any) => {
    e.stopPropagation();

    clickLike(description.me_liked || false);
  };

  const clickLike = (liked: boolean) => {
    if (!authHeader) return;
    if (liked) {
      Api.MyDescriptions.deleteApiDescriptionsMeUnlike(
        description.id as string,
        authHeader
      )
        .then((res) => {
          descriptionDispatch({
            type: "UPDATE_DESCRIPTION",
            payload: {
              description: {
                ...description,
                me_liked: false,
                total_likes: (description?.total_likes || 0) - 1,
              },
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });

      return;
    }

    Api.MyDescriptions.postApiDescriptionsMeLike(
      description.id as string,
      authHeader
    )
      .then((res) => {
        descriptionDispatch({
          type: "UPDATE_DESCRIPTION",
          payload: {
            description: {
              ...description,
              me_liked: true,
              total_likes: (description?.total_likes || 0) + 1,
            },
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const modalType = "LIKE_MODAL_" + description.id;
  const clickCountHandler = (e: any) => {
    e.stopPropagation();
    showModal(modalType);
  };

  return (
    <div
      className={[
        postItemStyles.like,
        isBlack ? postItemStyles.likeBlack : "",
      ].join(" ")}
    >
      <i
        onClick={clickLikeHandler}
        className={
          description.me_liked
            ? [postItemStyles.likeIconLiked, postItemStyles.likeIcon].join(" ")
            : postItemStyles.likeIcon
        }
      />
      {(description?.total_likes || 0) > 0 && (
        <span
          className={[
            postItemStyles.likeCount,
            isDetail ? [textStyle, utilStyles.fontSemiBold].join(" ") : "",
          ].join(" ")}
          onClick={clickCountHandler}
        >
          {description.total_likes || ""}
          {description.total_likes && isDetail && (
            <span
              className={[
                postItemStyles.likeCount,
                utilStyles.fontSemiBold,
                textStyle,
              ].join(" ")}
            >
              likes
            </span>
          )}
        </span>
      )}
      {baseState.modalType === modalType && (
        <LikeModal
          modalType={modalType}
          descriptionId={description?.id || ""}
        />
      )}
    </div>
  );
}
