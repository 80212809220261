import React from "react";
import utilStyles from "@/styles/utils.module.scss";
import UserHeader from "./UserHeader";
import { ShortUserView } from "@/api/generated";

export default function UserItem({
  user,
  relation = "",
}: {
  user: ShortUserView;
  relation?: string | null;
}) {
  const relationText =
    (relation || "").charAt(0).toUpperCase() + (relation || "").slice(1);

  return (
    <UserHeader user={user}>
      {relation && (
        <div className={utilStyles.userRight}>
          <span className={utilStyles.userRelation}>{relationText}</span>
        </div>
      )}
    </UserHeader>
  );
}
