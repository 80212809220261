import { useEffect, useState } from "react";
import useStorage from "@/hooks/useStorage";
import { KEY_AUTH_TOKEN } from "@/lib/storageKeys";

type AuthorizationType = {
  Authorization: string;
};

type AuthHeaderType = {
  headers: AuthorizationType;
};

type AuthHeaderWithMultiPartType = {
  headers: AuthorizationType;
  "Content-Type": string;
};

export function useAuthHeaders() {
  const { getLocalItem } = useStorage();

  const [authHeader, setAuthHeader] = useState<AuthHeaderType | null>(null);

  const [authHeaderWithMultiPart, setAuthHeaderWithMultiPart] =
    useState<AuthHeaderWithMultiPartType | null>();

  useEffect(() => {
    const authToken = getLocalItem(KEY_AUTH_TOKEN) || "";

    setAuthHeader({
      headers: { Authorization: `Bearer ${authToken}` },
    });
    setAuthHeaderWithMultiPart({
      headers: { Authorization: `Bearer ${authToken}` },
      "Content-Type": "multipart/form-data",
    });
  }, []);

  return { authHeader, authHeaderWithMultiPart };
}
